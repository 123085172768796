<template>
  <div class="finance-overview">
    <app-header :title="title" :isShowRefresh="true" @refresh="onRefresh"></app-header>

    <div>
      <el-form :inline="true" :model="form" class="demo-form-inline">
        <el-form-item label="统计时段">
          <el-date-picker 
            v-model="form.date" 
            type="monthrange"
            range-separator="至"
            format="yyyy-MM"
            value-format="yyyy-MM"
            start-placeholder="开始月份" 
            end-placeholder="结束月份">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
      <el-button-group>
        <el-button
          v-for="(item, index) in buts"
          :key="item.label"
          :type="item.type"
          @click="onSelect(index)"
        >{{item.label}}</el-button>
      </el-button-group>
    </div>
    <div v-loading="isLoading">
      <app-overview title="// 实收信息 //" :list="actualList" ></app-overview>
      <app-overview title="// 应收信息 //" :list="receivableList"></app-overview>
      <app-overview title="// 欠缴信息 //" :list="oweList"></app-overview>
      <app-overview title="// 退款信息 //" :list="refundList"></app-overview>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {
      isLoading: true,
      title: "财务概览",
      form: {
        date: ""
      },
      startMonth: "",
      endMonth: "",
      buts: [
        {
          type: "primary",
          label: "本月"
        },
        {
          type: "primary",
          label: "本年"
        },
        {
          type: "primary",
          label: "总数"
        }
      ],
      actualList: [
        {
          label: "实收总金额（元）",
          key: "actualTotalFee",
          value: ""
        },
        {
          label: "总订单数",
          key: "totalOrder",
          value: ""
        },
        {
          label: "实收总租金（元）",
          key: "actualTotalRentalFee",
          value: ""
        },
        {
          label: "实缴订单数",
          key: "actualPayedOrder",
          value: ""
        },
        {
          label: "实收总押金（元）",
          key: "actualTotalDepositFee",
          value: ""
        },
        {
          label: "总房间数",
          key: "totalRoom",
          value: ""
        },
        {
          label: "实收其他总金额（元）",
          key: "actualTotalOtherFee",
          value: ""
        },
        {
          label: "实缴房间数",
          key: "actualPayedRoom",
          value: ""
        }
      ],
      receivableList: [
        {
          label: "应收总金额（元）",
          key: "oughtTotalFee",
          value: ""
        },
        {
          label: "应缴订单数",
          key: "oughtOrder",
          value: ""
        },
        {
          label: "应收总租金（元）",
          key: "oughtTotalRentalFee",
          value: ""
        },
        {
          label: "应缴房间数",
          key: "oughtRoom",
          value: ""
        },
        {
          label: "应收总押金（元）",
          key: "oughtTotalDepositFee",
          value: "",
          isRow: true
        },
        {
          label: "应收其他总金额（元）",
          key: "oughtTotalOtherFee",
          value: "",
          isRow: true
        }
      ],
      oweList: [
        {
          label: "逾期欠缴总金额（元）",
          key: "overdueTotalFee",
          value: ""
        },
        {
          label: "逾期欠缴租金（元）：",
          key: "overdueTotalRentalFee",
          value: ""
        },
        {
          label: "逾期欠缴押金（元）：",
          key: "overdueTotalDepositFee",
          value: ""
        },
        {
          label: "逾期欠缴其他总金额（元）：",
          key: "overdueTotalOtherFee",
          value: ""
        },
        {
          label: "逾期欠缴订单数：",
          key: "overdueOrder",
          value: ""
        },
        {
          label: "逾期欠缴房间数：",
          key: "overdurRoom",
          value: ""
        }
      ],
      refundList: [
        {
          label: "实际应退款总金额（元）",
          key: "actualRefundableAmount",
          value: ""
        },
        {
          label: "退款到账金额（元）",
          key: "refundedAmountToAccount",
          value: ""
        },
        {
          label: "实际退款总金额（元）",
          key: "actualRefundedAmount",
          value: ""
        },
        {
          label: "押金应退款总金额（元）",
          key: "depositRefundableAmount",
          value: ""
        },
        {
          label: "应退款总金额（元）",
          key: "refundableAmount",
          value: ""
        },
        {
          label: "租金应退款总金额（元）",
          key: "rentalRefundableAmount",
          value: ""
        },
        {
          label: "扣款总金额（元）",
          key: "totalDeductionAmount",
          value: ""
        },
        {
          label: "物业费应退款总金额（元）",
          key: "propertyRefundableAmount",
          value: ""
        },
        {
          label: "退款失败金额（元）",
          key: "refundFailedAmount",
          value: ""
        },
      ]
    };
  },
  activated() {
    console.log("finance-overview created!!");
    this.startMonth = "";
    this.endMonth = "";
    this.form.date = null;
    for (let i = 0; i < this.buts.length; i++) {
        let item = this.buts[i];
        if (i == 2) {
          item.type = "success";
        } else {
          item.type = "primary ";
        }
    }
    this.onGet();
  },
  methods: {
    onRefresh() {
      this.onGet();
      console.log("refresh");
    },
    onSubmit() {
      if(this.form.date) {
        if(Array.isArray(this.form.date)) {
          let dto = {
            beginTime: this.form.date[0],
            endTime: this.form.date[1]
          };
          if(this.isMoreThan12MonthsApart(dto.beginTime, dto.endTime)) {
            return this.$message.warning('最大选择时间跨度不能超过12个月')
          }
        }else {
          this.form.date = null;
        }
      }
      
      for (let i = 0; i < this.buts.length; i++) {
        let item = this.buts[i];
        item.type = "primary ";
      }
      this.onGet();
    },
    onSelect(idx) {
      var newDate = new Date();
      var nowMonth = newDate.getMonth() + 1;
      var nowYea = newDate.getFullYear();
      let nowYearAndMonth =
        nowMonth.toString().length < 2
          ? nowYea + "-" + "0" + nowMonth
          : nowYea + "-" + nowMonth;
      if (idx === 0) {
        this.form.date = nowYearAndMonth;
        this.startMonth = nowYearAndMonth;
        this.endMonth = nowYearAndMonth;
      } else if (idx === 1) {
        this.form.date = nowYea.toString();
        // 当前年份的第一月
        this.startMonth = nowYea + "-01";
        // 当前月份
        this.endMonth = nowYearAndMonth;
      } else {
        //this.form.date = nowYea + "-" + "12";
        this.form.date = null;
      }

      for (let i = 0; i < this.buts.length; i++) {
        let item = this.buts[i];
        if (i == idx) {
          item.type = "success";
        } else if (item.type == "success") {
          item.type = "primary ";
        }
      }

      this.onGet();
    },
    onGet(opt) {
      this.isLoading = true;
      let dto = {};

      if (this.form.date) {
        // 按月份查询
        if (Array.isArray(this.form.date)) {
          dto.startMonth = this.form.date[0];
          dto.endMonth = this.form.date[1];
        } else {
          // 按本月/本年/总数查询
          dto.startMonth = this.startMonth;
          dto.endMonth = this.endMonth;
        }
      } else {
        dto.startMonth = "";
        dto.endMonth = "";
      }
      this.post(
        "/finance-service/financialStatistics/searchFinancialOverviewStatistics",
        dto,
        {
          isUseResponse: true,
        }
      ).then((res) => {
        this.isLoading = false;
        var data = res.data.data;
        var keys = [];
        for (var key in data) {
          keys.push(key);
        }
        this.receivableList.forEach((item) => {
          if (keys.indexOf(item["key"]) != -1) {
            item.value = data[keys[keys.indexOf(item["key"])]];
          }
        });
        this.actualList.forEach((item) => {
          if (keys.indexOf(item["key"]) != -1) {
            item.value = data[keys[keys.indexOf(item["key"])]];
          }
        });
        this.oweList.forEach((item) => {
          if (keys.indexOf(item["key"]) != -1) {
            item.value = data[keys[keys.indexOf(item["key"])]];
          }
        });
      });
      this.getRefundInfo(dto);
    },
    // 获取退款信息
    getRefundInfo(params) {
      let dto = {
        ...params,
      };
      this.post(
        "/finance-service/financialStatistics/getRefundStatistics",
        dto,
        {
          isUseResponse: true,
        }
      ).then((res) => {
        var data = res.data.data;
        var keys = [];
        for (var key in data) {
          keys.push(key);
        }
        if (res.data.code == 0) {
          this.refundList.forEach((item) => {
            if (keys.indexOf(item["key"]) != -1) {
              item.value = data[keys[keys.indexOf(item["key"])]];
            }
          });
        }
      });
    },

    isMoreThan12MonthsApart(date1, date2) {
      // 将日期字符串转换为Date对象
      const parseDate = (dateStr) => {
        const [year, month] = dateStr.split("-").map(Number);
        return new Date(year, month - 1); // 月份需要减1，因为Date对象的月份是从0开始的
      };

      const d1 = parseDate(date1);
      const d2 = parseDate(date2);

      // 计算两个日期之间的月份差
      const monthsDiff =
        (d2.getFullYear() - d1.getFullYear()) * 12 +
        (d2.getMonth() - d1.getMonth() + 1);

      // 判断是否超过12个月
      return monthsDiff > 12;
    },
  },
};
</script>
<style lang="scss">
.finance-overview {
  .row-label {
    text-align: right;
  }

  .data-box {
    margin-bottom: 30px;
    max-width: 1200px;

    .data-table {
      color: #606266;
      border: solid 1px #e4e7ed;
      font-size: 14px;

      .row-label {
        background-color: #ebeef5;
      }
      .el-col {
        padding: 10px;
        height: 40px;
        line-height: 20px;
        border-left: solid 1px #e4e7ed;
      }

      .el-row {
        border-bottom: solid 1px #e4e7ed;
      }

      .el-col:nth-child(1),
      .el-row:nth-last-child(1) {
        border-bottom: none;
      }
    }

    .data-title {
      margin-bottom: 20px;
      border-bottom: solid 1px #e4e7ed;
      text-align: center;
      span {
        position: relative;
        padding: 0 10px;
        top: 10px;
        font-size: 15px;
        font-weight: bold;
        color: #909399;
        background-color: #ffffff;
      }
    }
  }
}
</style>
